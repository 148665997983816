import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/NotFound.css'; // Make sure to create a NotFound.css file

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 20); // Redirect after 5 seconds

    // Clean up the timer if the component unmounts before the timer fires
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="NotFound-container">
      <h1 className="NotFound-title">404</h1>
      <p className="NotFound-subtitle">Oops! The page you're looking for isn't here.</p>
      <p>You will be redirected to the homepage in 5 seconds.</p>
    </div>
  );
};

export default NotFound;